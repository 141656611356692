export const currentApi = () => {
  const url = window.location.href;

  if (url.includes("ngir.ordreportal.no")) {
    // NGIR Production
    return {
      api: "https://www.ngirkart.no/api",
      customer: "NGIR",
      webSocketUrl: "ngirkart.no",
    };
  } else if (url.includes("ngir-ordreportal.avinet.no")) {
    // NGIR Development
    return {
      api: "https://ngirdo.avinet.no/api",
      customer: "NGIR",
      webSocketUrl: "ngirdo.avinet.no",
    };
  } else if (url.includes("ngir-order.vercel.app")) {
    // NGIR Vercel
    return {
      api: "https://ngirdo.avinet.no/api",
      customer: "NGIR",
      webSocketUrl: "ngirdo.avinet.no",
    };
  } else if (url.includes("demo.ordreportal.no")) {
    return {
      api: "https://demo.aveg.no/api",
      customer: "AVEG",
      webSocketUrl: "demo.aveg.no",
    };
  } else {
    // Default (Localhost)
    return {
      api: "https://ngirdo.avinet.no/api",
      customer: "NGIR",
      webSocketUrl: "ngirdo.avinet.no",
    }; // Change to the project you are working on (currently NGIR Development)
  }
};
