import React, { createContext, useState, useContext, ReactNode } from "react";
import { InternalMessage } from "../../utils/data-types";

interface WebSocketContextType {
  data: InternalMessage | undefined;
  setData: (data: InternalMessage | undefined) => void;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined
);

export const WebSocketProvider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState<InternalMessage | undefined>(); // Store WebSocket data here

  return (
    <WebSocketContext.Provider value={{ data, setData }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
