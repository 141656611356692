import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";

import { InternalMessage, OrderObject } from "../../utils/data-types";
import { useCallback, useEffect, useRef } from "react";
import { STATUSES } from "../../constants/Constants";
import { useTranslation } from "react-i18next";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { Text } from "../../components/text/Text";

type OrderObjectListProps =
  | {
      tasks: OrderObject[] | undefined;
      selectedBoxId: number | null;
      needStartweight: boolean;
      selectedTaskFilter: string;
      handleBoxClick: (id: number) => void;
      onDragEnd: (result: DropResult) => void;
      enableDragAndDrop: boolean;
    }
  | {
      tasks: InternalMessage[] | undefined;
      selectedBoxId: number | null;
      needStartweight: boolean;
      selectedTaskFilter: string;
      handleBoxClick: (id: number) => void;
      onDragEnd?: undefined;
      enableDragAndDrop?: false | undefined;
    };

export const OrderObjectList = ({
  tasks,
  selectedBoxId,
  needStartweight,
  handleBoxClick,
  onDragEnd,
  enableDragAndDrop,
}: OrderObjectListProps) => {
  const { t } = useTranslation();

  const taskRefs = useRef<(HTMLDivElement | null)[]>([]);

  const scrollToTask = useCallback((index: number) => {
    const taskElement = taskRefs.current[index];
    if (taskElement) {
      taskElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  useEffect(() => {
    if (selectedBoxId !== null) {
      const index = tasks?.findIndex(
        (orderObject) => orderObject.id === selectedBoxId
      );
      if (index !== undefined && index !== -1) {
        scrollToTask(index);
      }
    }
  }, [selectedBoxId, tasks, scrollToTask]);

  function handleSetTypeIcon(status: string, isSelected: boolean): string {
    switch (status) {
      case STATUSES.REPORTED:
        return isSelected ? "selectedOrdered" : "ordered";
      case STATUSES.COMPLETED:
        return isSelected ? "selectedCheckmark" : "checkmark";
      case STATUSES.ORDERED:
        return isSelected ? "selectedOrdered" : "ordered";
      case STATUSES.NOT_COMPLETED:
        return isSelected ? "selectedNotCompleted" : "notCompleted";
      default:
        return "";
    }
  }

  return enableDragAndDrop && onDragEnd ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            className={`order-info-tasks-boxes ${
              needStartweight ? "bottom-padding" : ""
            }`}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {tasks?.map((task: OrderObject, taskIndex: number) => (
              <Draggable
                key={task.id}
                draggableId={task.id.toString()}
                index={taskIndex}
              >
                {(provided) => (
                  <div
                    ref={(el) => {
                      if (el) {
                        provided.innerRef(el);
                        taskRefs.current[taskIndex] = el;
                      }
                    }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`order-info-tasks-box ${
                      selectedBoxId === task.id ? "selected" : ""
                    } ${task.status}`}
                    onClick={() => handleBoxClick(task.id)}
                  >
                    <div className="order-info-tasks-box-left">
                      <Icon
                        name={handleSetTypeIcon(
                          task.status,
                          selectedBoxId === task.id
                        )}
                      />
                      <Text text={t(`common.${task.status}`)} size="xxxs" />
                    </div>
                    <div className="order-info-tasks-box-middle">
                      <Text text={task.codedesc} size="xxxs" />
                      <Text text={task.info1} size="xxs" fontWeight={600} />
                      <Text text={task.info2} size="xxxs" />
                      <Text text={task.info3} size="xxxs" />
                    </div>
                    <div className="order-info-tasks-box-right">
                      {task.attachments > 0 && (
                        <div className="task-attachment">
                          <Icon name="attach" />
                        </div>
                      )}
                      <Icon name="arrow" />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <div className="order-info-tasks-boxes">
      {tasks?.map((task, taskIndex: number) => {
        const diaryTask = task as InternalMessage;
        return (
          <div
            key={diaryTask.id}
            ref={(el) => (taskRefs.current[taskIndex] = el)}
            className={`order-info-tasks-box ${
              selectedBoxId === diaryTask.id ? "selected" : ""
            } ${diaryTask.status}`}
            onClick={() => handleBoxClick(diaryTask.id)}
          >
            <div className="order-info-tasks-box-left">
              <Icon
                name={handleSetTypeIcon(
                  diaryTask.status,
                  selectedBoxId === diaryTask.id
                )}
              />
              <Text text={t(`common.${diaryTask.status}`)} size="xxxs" />
            </div>
            <div className="order-info-tasks-box-middle">
              <Text text={diaryTask.codedesc} size="xxxs" />
              <Text text={diaryTask.info1} size="xxs" fontWeight={600} />
            </div>
            <div className="order-info-tasks-box-right">
              {diaryTask.attachments > 0 && (
                <div className="task-attachment">
                  <Icon name="attach" />
                </div>
              )}
              <Icon name="arrow" />
            </div>
          </div>
        );
      })}
    </div>
  );
};
