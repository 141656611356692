import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./OrderInfo.scss";
import Feature from "ol/Feature";
import WKT from "ol/format/WKT";
import { Text } from "../../components/text/Text";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import PageLayout from "../../components/pagelayout/PageLayout";
import { MapWrapper } from "../../components/map/MapWrapper";
import {
  IRegisterData,
  IOrder,
  OrderObject,
  IDrivingRequest,
} from "../../utils/data-types";
import { t } from "i18next";
import { MapProvider } from "@avinet/adaptive-ui-maps";
import { projection } from "../../constants/Projection";
import { getResponse, setResponse } from "../../utils/indexedDb";
import superagent from "superagent";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import { MapOptions } from "../../components/map-options/MapOptions";
import BarcodeScanner from "../../components/barcode-scanner/BarcodeScanner";
import { MAP_OPTIONS, STATUSES } from "../../constants/Constants";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import TaskFilterButton from "../../components/buttons/TaskFilterButton";
import { ordersOverviewUrl } from "../../constants/Routes";
import { useOrder } from "../../context/order-context/OrderContext";
import Modal from "../../components/modal/Modal";
import { AttachmentModalContent } from "../../components/modal/AttachmentModalContent";
import { OrderInfoModalContent } from "../../components/modal/OrderInfoModalContent";
import { DrivingModalContent } from "../../components/modal/DrivingModalContent";
import { TextButton } from "../../components/buttons/TextButton";
import { useDriving } from "../../context/driving-context/DrivingContext";
import IconWithBadge from "../../components/icon-with-badge/IconWithBadge";
import { useDrawing } from "../../context/drawing-context/DrawingContext";
import FlipButtons from "../../components/buttons/FlipButtons";
import { useMobileView } from "../../context/mobile-view-context/MobileViewContext";
import { DropResult } from "@hello-pangea/dnd";
import { ConfirmationModalContent } from "../../components/modal/ConfirmationModalContent";
import { OrderObjectList } from "../../components/order-object-list/OrderObjectList";
import { UserContext } from "../../context/user-context/UserContext";

const OrderInfo = () => {
  const navigate = useNavigate();
  const {
    isDrawMode,
    setIsDrawMode,
    hasDrawn,
    setHasDrawn,
    setDrawnObject,
    setIsSendingWKT,
  } = useDrawing();
  const { user } = useContext(UserContext);
  const { saveOrder } = useOrder();
  const { isMobileView } = useMobileView();
  const { isFullscreenMap, onToggleFullscreenMap } = useMobileView();
  const { orderId: orderId } = useParams();
  const { selectedFilter: selectedFilter } = useParams();
  const [showModal, setShowModal] = useState<string>("");
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [regRequestsInQueue, setRegRequestsInQueue] = useState<number>(0);
  const [drivingRequestsInQueue, setDrivingRequestsInQueue] =
    useState<number>(0);
  const [showSyncedDataText, setShowSyncedDataText] = useState<boolean>(false);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [tasks, setTasks] = useState<OrderObject[]>();
  const [tempOrderState, setTempOrderState] = useState<{
    reorderedTasks: OrderObject[];
    newOrderRequest: {
      order_id: number;
      sortorder_prev: number;
      sortorder_new: number;
    };
  } | null>(null);
  const mapContentRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [selectedBoxId, setSelectedBoxId] = useState<number | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [showScanner, setShowScanner] = useState<boolean>(false);
  const [scanResult, setScanResult] = useState<string | undefined>(undefined);

  const { isDriving, setIsDriving } = useDriving();

  const needStartweight =
    localStorage.getItem(LOCAL_STORAGE.NEED_STARTWEIGHT) === "true";

  const jobUuid = localStorage.getItem(LOCAL_STORAGE.JOB_UUID);

  useEffect(() => {
    if (jobUuid) {
      setIsDriving(true);
    } else {
      setIsDriving(false);
    }
  }, [jobUuid, setIsDriving]);

  const [showStartDrivingModal, setShowStartDrivingModal] =
    useState<boolean>(false);
  const [showEndDrivingModal, setEndShowDrivingModal] =
    useState<boolean>(false);

  const handleToggleDrivingModal = useCallback(() => {
    if (isDriving) {
      setEndShowDrivingModal((prev) => !prev);
    } else {
      setShowStartDrivingModal((prev) => !prev);
    }
  }, [isDriving]);

  const renderDrivingButton = useMemo(() => {
    if (!needStartweight) return null;
    if (isDriving) {
      return (
        <TextButton
          className="driving-btn stop-driving"
          text={t("pages.orders.endDriving")}
          onClick={handleToggleDrivingModal}
          icon={<Icon name="carClock" />}
          bold
        />
      );
    } else {
      return (
        <TextButton
          className="driving-btn start-driving"
          text={t("pages.orders.startDriving")}
          onClick={handleToggleDrivingModal}
          icon={<Icon name="carClock" />}
          bold
        />
      );
    }
  }, [handleToggleDrivingModal, isDriving, needStartweight]);

  const handleOpenModal = useCallback(
    (modal: string) => setShowModal(modal),
    []
  );
  const handleCloseModal = useCallback(() => setShowModal(""), []);

  const availableFilters = useMemo<string[]>(
    () => [STATUSES.ORDERED, STATUSES.NOT_COMPLETED, STATUSES.COMPLETED],
    []
  );
  const [selectedTaskFilter, setSelectedTaskFilter] = useState<string>(
    STATUSES.ALL
  );

  const order = useMemo(() => {
    return orders?.find((order) => order.id === parseInt(orderId ?? ""));
  }, [orderId, orders]);

  //get orders from indexedDB
  useEffect(() => {
    (async () => {
      if (!selectedFilter) return;
      const ordersFromIndexedDB = await getResponse(selectedFilter);
      setOrders(ordersFromIndexedDB);
    })();
  }, [selectedFilter]);

  const filteredOrderObjects = order?.orderobject?.filter((task) =>
    selectedTaskFilter === STATUSES.ALL
      ? true
      : task.status === selectedTaskFilter
  );

  const orderWithfilteredOrderObjects = useMemo(() => {
    const newObj = {
      ...order,
      orderobject: order?.orderobject?.filter((task) =>
        selectedTaskFilter === STATUSES.ALL
          ? true
          : task.status === selectedTaskFilter
      ),
    };
    setTasks(newObj.orderobject);
    return order;
  }, [order, selectedTaskFilter]);

  const features = useMemo<Feature[]>(() => {
    if (!filteredOrderObjects || filteredOrderObjects.length === 0) return [];
    return filteredOrderObjects.map((task) => {
      if (task.wkt === "" || task.wkt === null) {
        return new Feature();
      }
      const feature = new WKT().readFeature(task.wkt);
      feature.setId(task.id);
      feature.setProperties({ status: task.status, name: task.info1 });
      const geometry = feature.getGeometry();
      if (geometry) {
        geometry.transform(projection, "EPSG:3857");
      }
      return feature;
    });
  }, [filteredOrderObjects]);

  const dummyExtent = useMemo(() => [1000000, 9000000, 400000, 8300000], []);

  const defaultExtent = useMemo(() => [461314, 8462004, 707752, 8715243], []);

  const extent = useMemo(() => {
    const extentFromStore = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE.MAP_EXTENT) ?? ""
    );
    if (
      extentFromStore !== defaultExtent &&
      extentFromStore.every((value: number | null) => value !== null)
    ) {
      return extentFromStore;
    }
    if (features.length === 0) return defaultExtent;
    const extent: number[] = [...dummyExtent];
    for (let i = 0; i < features.length; i++) {
      const geometry = features[i].getGeometry();
      if (geometry) {
        const featureExtent = geometry.getExtent();
        extent[0] = Math.min(extent[0], featureExtent[0]);
        extent[1] = Math.min(extent[1], featureExtent[1]);
        extent[2] = Math.max(extent[2], featureExtent[2]);
        extent[3] = Math.max(extent[3], featureExtent[3]);
      }
    }

    const buffer = Math.max(
      (extent[2] - extent[0]) / 5,
      (extent[3] - extent[1]) / 5
    );
    extent[0] -= buffer;
    extent[1] -= buffer;
    extent[2] += buffer;
    extent[3] += buffer;
    if (extent === dummyExtent) {
      return defaultExtent;
    }
    return extent;
  }, [defaultExtent, features, dummyExtent]);

  const handleSetSelectedBoxId = useCallback(
    (id: number | null) => setSelectedBoxId(id),
    []
  );

  const handleBoxClick = useCallback(
    (index: number) => {
      handleSetSelectedBoxId(index === selectedBoxId ? null : index);
      if (index === selectedBoxId) {
        setSelectedOption("");
      } else if (selectedBoxId === null || selectedOption === "") {
        setSelectedOption(MAP_OPTIONS.REGISTER);
      }
    },
    [handleSetSelectedBoxId, selectedBoxId, selectedOption]
  );

  useEffect(() => {
    if (selectedBoxId === null) {
      setIsSendingWKT(false);
    }
  }, [selectedBoxId, setIsSendingWKT]);

  const handleSetSelectedTaskFilter = useCallback((filter: string) => {
    if (filter === STATUSES.ALL) {
      setSelectedTaskFilter(STATUSES.ALL);
    } else {
      setSelectedTaskFilter(filter);
    }
  }, []);

  /**
   * if the selected box is filtered away,
   * deselect the box
   */
  useEffect(() => {
    if (
      selectedBoxId !== null &&
      !orderWithfilteredOrderObjects?.orderobject?.some(
        (obj) => obj.id === selectedBoxId
      )
    ) {
      setSelectedBoxId(null);
    }
  }, [orderWithfilteredOrderObjects?.orderobject, selectedBoxId]);

  const registerUrl = useMemo(
    () =>
      DEFAULT_API_CONFIG.url +
      "/diaryorder/diarydetailV2/updateMultipleDetails",
    []
  );

  const retryFailedRegRequests = useCallback(async () => {
    if (!user.value?.session_id || !user.value?.expiration_time) return;
    if (user.value?.expiration_time < new Date().getTime() / 1000) return;
    try {
      let failedRegRequests = await getResponse("failedRegRequests");

      for (const request of failedRegRequests) {
        try {
          await superagent
            .post(registerUrl)
            .send(request.requestBody)
            .set("Accept", "application/json")
            .set("gm_session_id", user.value.session_id);

          const updatedFailedRegRequests = failedRegRequests.filter(
            (failedRegRequest: IRegisterData) => failedRegRequest !== request
          );

          await setResponse("failedRegRequests", updatedFailedRegRequests);

          failedRegRequests = updatedFailedRegRequests;
        } catch (error) {
          console.error("Retry failed for request:", request, "Error:", error);
        }
      }
    } catch (error) {
      console.error(
        "An error occurred during retrying failed requests:",
        error
      );
    }
  }, [registerUrl, user.value?.expiration_time, user.value?.session_id]);

  const retryFailedDrivingRequests = useCallback(async () => {
    if (!user.value?.session_id) return;

    try {
      let failedDrivingRequests = await getResponse("failedDrivingRequests");

      for (const request of failedDrivingRequests) {
        try {
          await superagent
            .put(DEFAULT_API_CONFIG.url + "/diaryorder/job")
            .send(request.requestBody)
            .set("Accept", "application/json")
            .set("gm_session_id", user.value?.session_id);

          const updatedFailedDrivingRequests = failedDrivingRequests.filter(
            (failedDrivingRequest: IDrivingRequest) =>
              failedDrivingRequest !== request
          );

          await setResponse(
            "failedDrivingRequests",
            updatedFailedDrivingRequests
          );

          failedDrivingRequests = updatedFailedDrivingRequests;
        } catch (error) {
          console.error("Retry failed for request:", request, "Error:", error);
        }
      }
    } catch (error) {
      console.error(
        "An error occurred during retrying failed requests:",
        error
      );
    }
  }, [user.value?.session_id]);

  useEffect(() => {
    const interval = setInterval(() => {
      async function fetchData() {
        const requestsInQueue = await getResponse("failedRegRequests");
        setRegRequestsInQueue(requestsInQueue?.length);
        const drivingRequestsInQueue = await getResponse(
          "failedDrivingRequests"
        );
        setDrivingRequestsInQueue(drivingRequestsInQueue?.length);
      }
      fetchData();
    }, 1000);

    return () => clearInterval(interval);
  }, [regRequestsInQueue]);

  useEffect(() => {
    window.addEventListener("online", () => setIsOnline(true));
    window.addEventListener("offline", () => setIsOnline(false));

    return () => {
      window.removeEventListener("online", () => setIsOnline(true));
      window.removeEventListener("offline", () => setIsOnline(false));
    };
  }, []);

  const offlineString = useMemo(() => {
    if (!isOnline) {
      return t("pages.orderInfo.offlineTextQueue", {
        amount: regRequestsInQueue,
      });
    }
    if (isOnline && regRequestsInQueue > 0) {
      return t("pages.orderInfo.onlineText", {
        amount: regRequestsInQueue,
      });
    }
    if (isOnline && showSyncedDataText) {
      return t("pages.orderInfo.syncedDataText");
    }
    return "";
  }, [regRequestsInQueue, isOnline, showSyncedDataText]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isOnline && regRequestsInQueue > 0) {
        retryFailedRegRequests();
        setShowSyncedDataText(true);
      } else if (isOnline && regRequestsInQueue === 0) {
        setShowSyncedDataText(false);
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [
    regRequestsInQueue,
    isOnline,
    retryFailedRegRequests,
    showSyncedDataText,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isOnline && drivingRequestsInQueue > 0) {
        retryFailedDrivingRequests();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [drivingRequestsInQueue, isOnline, retryFailedDrivingRequests]);

  const handleSelectedOptionChange = useCallback((option: string) => {
    setSelectedOption(option);
  }, []);

  const handleShowScanner = useCallback(
    () => setShowScanner((prev) => !prev),
    []
  );

  const handleScanResult = useCallback((result: string) => {
    setScanResult(result);
  }, []);

  //if scanResult is not null, find the orderobject that has an info1.split[0] that matches the first 6 characters of the scanResult
  useEffect(() => {
    if (scanResult !== undefined) {
      const orderObject = orderWithfilteredOrderObjects?.orderobject?.find(
        (orderObject) =>
          orderObject.info1.split(" ")[0] === scanResult.substring(0, 6)
      );

      if (orderObject) {
        setSelectedBoxId(orderObject.id);
        setScanResult(undefined);
        handleShowScanner();
      } else {
        setScanResult(undefined);
      }
    }
  }, [
    handleScanResult,
    handleShowScanner,
    orderWithfilteredOrderObjects?.orderobject,
    scanResult,
  ]);

  //whenever selectedBoxId is set to null, clear the scanResult
  useEffect(() => {
    if (selectedBoxId === null) {
      setScanResult(undefined);
    }
  }, [selectedBoxId]);

  const handleNavigateToOrdersOverview = useCallback(() => {
    navigate(ordersOverviewUrl);
    saveOrder(null);
  }, [navigate, saveOrder]);

  const handleCancelDrawMode = useCallback(
    (option: string) => {
      handleSelectedOptionChange(option ?? "");
      setIsDrawMode(false);
      setDrawnObject(null);
      setHasDrawn(false);
    },
    [handleSelectedOptionChange, setDrawnObject, setHasDrawn, setIsDrawMode]
  );

  const handleDoneDrawing = useCallback(() => {
    setHasDrawn(true);
    setIsDrawMode(false);
  }, [setHasDrawn, setIsDrawMode]);

  const onDragEnd = (result: DropResult) => {
    // Dropped outside the list
    if (!result.destination) return;

    if (!tasks) return;
    const reorderedTasks = Array.from(tasks);
    const [removed] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, removed);

    const newOrderRequest = {
      order_id: Number(orderId),
      sortorder_prev: removed.sortorder,
      sortorder_new: tasks[result.destination.index].sortorder,
    };

    if (newOrderRequest.sortorder_prev === newOrderRequest.sortorder_new)
      return;

    setTempOrderState({ reorderedTasks, newOrderRequest });
    setShowConfirmationModal(true);
  };

  const onChangeOrder = useCallback(() => {
    if (!tempOrderState) return;

    const { reorderedTasks, newOrderRequest } = tempOrderState;

    setTasks(reorderedTasks);

    const changeSortOrderUrl =
      DEFAULT_API_CONFIG.url + "/diaryorder/orderobject/changesortorder";

    const user_store = localStorage.getItem(LOCAL_STORAGE.USER);
    const userObject = user_store ? JSON.parse(user_store) : {};
    const gm_session_id = userObject?.session_id ?? "";

    const changeSortOrder = async () => {
      try {
        await superagent
          .post(changeSortOrderUrl)
          .send(newOrderRequest)
          .set("Accept", "application/json")
          .set("gm_session_id", gm_session_id);
      } catch (error) {
        console.error("Error changing sort order:", error);
      }
    };

    changeSortOrder();

    setShowConfirmationModal(false);
    setTempOrderState(null);
  }, [tempOrderState]);

  const currentMovingTask = useMemo(
    () =>
      tasks?.find(
        (task) =>
          task.sortorder === tempOrderState?.newOrderRequest.sortorder_prev
      ),
    [tasks, tempOrderState]
  );

  const digiThemeUuid = useMemo(() => {
    return order?.orderobject?.find((obj) => obj.id === selectedBoxId)
      ?.digi_theme_uuid;
  }, [order, selectedBoxId]);

  return (
    <>
      <PageLayout orderBtnActive>
        <div className={`order-info ${isMobileView ? "mobile" : ""}`}>
          {!isFullscreenMap && (
            <div
              className={`order-info-tasks ${isMobileView ? "small" : "large"}`}
            >
              <div className="order-info-tasks-nav-header">
                <div
                  className="header-gap"
                  onClick={handleNavigateToOrdersOverview}
                >
                  <Icon name="arrow" flip />
                  <Text text={t("pages.orderInfo.ordersOverview")} size="xxs" />
                </div>
                {!isMobileView && (
                  <div
                    className="expand-container"
                    onClick={onToggleFullscreenMap}
                  >
                    <Icon
                      name="chevron"
                      className="expand-icon"
                      flip={!isFullscreenMap}
                    />
                  </div>
                )}
              </div>
              <div className="order-info-tasks-header">
                <Text
                  text={orderWithfilteredOrderObjects?.name ?? ""}
                  size="xs"
                  fontWeight={600}
                />
                <div className="view-options-container">
                  <button
                    className="view-option-button"
                    onClick={handleShowScanner}
                  >
                    <Icon name="scanCamera" />
                  </button>
                  <button
                    className={"view-option-button"}
                    onClick={() => handleOpenModal("orderInfo")}
                  >
                    <Icon name="info" />
                  </button>
                  <button
                    className={"view-option-button"}
                    onClick={() => handleOpenModal("attachment")}
                  >
                    <IconWithBadge
                      icon={<Icon name="attach" />}
                      count={order?.attachments ?? 0}
                    />
                  </button>
                </div>
              </div>

              <div className="hr" />

              <div className="order-info-tasks-filter">
                <TaskFilterButton
                  number={order?.orderobject?.length ?? 0}
                  text={t("common.all")}
                  selected={selectedTaskFilter === STATUSES.ALL}
                  onSelect={() => handleSetSelectedTaskFilter(STATUSES.ALL)}
                />
                {availableFilters.map((filter, index) => (
                  <TaskFilterButton
                    key={index}
                    number={
                      order?.orderobject?.filter((obj) => obj.status === filter)
                        .length ?? 0
                    }
                    text={t(`common.${filter}`)}
                    selected={selectedTaskFilter.includes(filter)}
                    onSelect={() => handleSetSelectedTaskFilter(filter)}
                  />
                ))}
              </div>

              <OrderObjectList
                tasks={
                  selectedTaskFilter === STATUSES.ALL
                    ? (tasks as OrderObject[])
                    : (filteredOrderObjects as OrderObject[])
                }
                selectedBoxId={selectedBoxId}
                needStartweight={needStartweight}
                selectedTaskFilter={selectedTaskFilter}
                handleBoxClick={handleBoxClick}
                onDragEnd={onDragEnd}
                enableDragAndDrop={selectedTaskFilter === STATUSES.ALL}
              />

              {renderDrivingButton}
            </div>
          )}
          {isMobileView && selectedBoxId && (
            <Modal
              show={selectedBoxId !== null}
              closeModal={() => setSelectedBoxId(null)}
              provideOwnContent
            >
              <MapOptions
                className="mobile"
                dataFromOrderinfo={order}
                digiThemeUuid={digiThemeUuid}
                selectedBoxId={selectedBoxId}
                selectedFilter={selectedFilter}
                onSelectedOptionChange={handleSelectedOptionChange}
                selectedOptionFromParent={selectedOption}
                clearSelectedBoxId={() => setSelectedBoxId(null)}
                handleCloseMessagePopup={() => handleSelectedOptionChange("")}
                showRegisterButton
                isMobileView
              />
            </Modal>
          )}
          {!isMobileView && (
            <MapOptions
              className={`${
                isFullscreenMap
                  ? "collapsed-options-menu"
                  : "expanded-options-menu"
              }`}
              key={selectedBoxId}
              dataFromOrderinfo={order}
              digiThemeUuid={digiThemeUuid}
              selectedBoxId={selectedBoxId}
              selectedFilter={selectedFilter}
              onSelectedOptionChange={handleSelectedOptionChange}
              selectedOptionFromParent={selectedOption}
              handleCloseMessagePopup={() => handleSelectedOptionChange("")}
              showRegisterButton
            />
          )}
          {isDrawMode && (
            <FlipButtons
              onCancel={() => handleCancelDrawMode(selectedOption)}
              hasDrawn={hasDrawn}
              onDone={handleDoneDrawing}
            />
          )}
        </div>
      </PageLayout>
      {!isMobileView && (
        <div
          className={`order-info-map ${isFullscreenMap ? "fullscreen" : ""}`}
        >
          <MapProvider initialExtent={extent} minZoom={6} maxZoom={18}>
            <MapWrapper
              selectedId={selectedBoxId}
              defaultExtent={extent}
              featuresWKT1={features}
              featuresWKT2={features}
              selectedOption={selectedOption}
              onFeatureMapClick={handleSetSelectedBoxId}
              order={order}
            >
              <div ref={mapContentRef} className="map-layout--content">
                <Outlet />
              </div>
            </MapWrapper>
          </MapProvider>
        </div>
      )}
      {isFullscreenMap && (
        <div className="collapse-container" onClick={onToggleFullscreenMap}>
          <Icon
            name="chevron"
            className="expand-icon"
            flip={!isFullscreenMap}
          />
        </div>
      )}
      <Text
        className={`connection-text ${
          isOnline ? "online-text" : "offline-text"
        }`}
        text={offlineString}
        size="xxxs"
      />
      <BarcodeScanner
        isScanning={showScanner}
        onClose={handleShowScanner}
        onScan={handleScanResult}
      />
      <Modal
        show={showModal === "attachment"}
        title={t("pages.attachments.attachments")}
        icon={<Icon name="attach" />}
        closeModal={handleCloseModal}
      >
        <AttachmentModalContent
          link_uuid={order?.order_uuid ?? ""}
          onClose={handleCloseModal}
        />
      </Modal>
      <Modal
        show={showModal === "orderInfo"}
        title={t("common.info")}
        icon={<Icon name="info" />}
        closeModal={handleCloseModal}
      >
        <OrderInfoModalContent order={order} onClose={handleCloseModal} />
      </Modal>
      <Modal
        show={showStartDrivingModal}
        title={t("pages.orders.startingWeight")}
        icon={<Icon name="weight" />}
        closeModal={handleToggleDrivingModal}
      >
        <DrivingModalContent onClose={handleToggleDrivingModal} />
      </Modal>
      <Modal
        show={showEndDrivingModal}
        title={t("pages.orders.finalWeight")}
        icon={<Icon name="weight" />}
        closeModal={handleToggleDrivingModal}
        noBackdropClose
      >
        <DrivingModalContent onClose={handleToggleDrivingModal} />
      </Modal>

      <Modal
        show={showConfirmationModal}
        title={t("pages.orderInfo.confirmationModal.title")}
        icon={<Icon name="info" />}
        closeModal={() => {
          setShowConfirmationModal(false);
          setTempOrderState(null);
        }}
      >
        <ConfirmationModalContent
          onConfirm={onChangeOrder}
          onclose={() => {
            setShowConfirmationModal(false);
            setTempOrderState(null);
          }}
          orderObject={currentMovingTask}
        />
      </Modal>
    </>
  );
};

export default OrderInfo;
