import { useContext, useEffect } from "react";

import { LoadingMask } from "../components/loading/LoadingMask";
import { UserContext } from "../context/user-context/UserContext";
import { Login } from "../pages/login/Login";

export function ProtectedRoute({
  children,
  onSessionExpired,
}: {
  children: JSX.Element;
  onSessionExpired: () => void;
}) {
  const { isLogin, user } = useContext(UserContext);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        user.value?.expiration_time &&
        user.value.expiration_time < new Date().getTime() / 1000
      ) {
        onSessionExpired();
        clearInterval(interval);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [onSessionExpired, user.value?.expiration_time]);

  if (!user) {
    return <LoadingMask />;
  }

  if (isLogin === undefined) {
    return <LoadingMask />;
  }

  if (!isLogin) {
    return <Login />;
  }

  return children;
}
