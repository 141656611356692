export enum STATUSES {
  ALL = "Alle",
  COMPLETED = "Fullført",
  ORDERED = "Bestilt",
  NOT_COMPLETED = "Ikke fullført",
  STARTED = "Startet",
  REPORTED = "Meldt",
  ROUTE = "Rute",
}

export enum DRAWING_OBJECT_TYPES {
  POINT = "point",
  LINE = "line",
}

export enum MAP_OPTIONS {
  REGISTER = "register",
  MESSAGE = "message",
  PROPERTIES = "properties",
  LOG = "log",
  ZOOM_TO = "zoomTo",
}
