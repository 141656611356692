import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Text } from "../text/Text";
import "./Log.scss";
import { t } from "i18next";
import useDataFetch from "../../hooks/useDataFetch";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { DiaryDetails } from "../../utils/data-types";
import Attachments from "../attachments/Attachments";
import LogDetails from "./log-details/LogDetails";
import { LoadingMask } from "../loading/LoadingMask";
import parse from "html-react-parser";

const Log = ({
  className,
  diarydetailsRequest,
  orderobjectFkUuid,
  onClose,
}: {
  className?: string;
  diarydetailsRequest: Record<string, unknown>;
  orderobjectFkUuid: string | undefined;
  onClose: () => void;
}) => {
  const [listView, setListView] = useState<boolean>(true);
  const [selectedLogEvent, setSelectedLogEvent] = useState<DiaryDetails | null>(
    null
  );

  useEffect(() => {
    setListView(true);
  }, [orderobjectFkUuid]);

  const handleSetView = useCallback(() => {
    setListView((prev) => !prev);
  }, []);

  const handleEventClick = useCallback(
    (item: DiaryDetails) => {
      setSelectedLogEvent(item);
      handleSetView();
    },
    [handleSetView]
  );

  const logUrl = useMemo<string>(
    () => DEFAULT_API_CONFIG.url + "/diaryorder/diarydetailV2/getDiaryDetails",
    []
  );

  const [data, loading] = useDataFetch<DiaryDetails[]>(
    logUrl,
    "POST",
    diarydetailsRequest
  );

  const trimDate = useCallback((date: string) => {
    return date.split("T")[0];
  }, []);

  const isMatchingLogUuid = useCallback(
    (uuid: string) => {
      return orderobjectFkUuid === uuid ? "matching" : "";
    },
    [orderobjectFkUuid]
  );

  return (
    <>
      {listView ? (
        <div className={`log ${className}`}>
          <div className="log-header">
            <Text
              className="log-title"
              text={t("pages.log.title")}
              size="xxs"
              fontWeight={600}
            />
            <div className="log-header-close-btn" onClick={onClose}>
              <Icon name="cross" />
            </div>
          </div>
          {loading ? (
            <LoadingMask small />
          ) : (
            data &&
            data.length > 0 &&
            !loading &&
            data.map((item: DiaryDetails, index: number) => (
              <div
                className={"log-row"}
                key={index}
                onClick={() => handleEventClick(item)}
              >
                <div className="log-column" key={index}>
                  <Text
                    className={`${isMatchingLogUuid(item.uuid)}`}
                    text={
                      trimDate(item.date) +
                      " - " +
                      item.status +
                      " - " +
                      item.codedesc
                    }
                    size="xxxs"
                    fontWeight={600}
                  />
                  <Text
                    className="log-column-value"
                    text={
                      item.comment === ""
                        ? "-"
                        : (parse(item.comment) as string)
                    }
                    size="xxxs"
                  />{" "}
                </div>
                <div className="icon-row">
                  {item.attachments > 0 && (
                    <Icon className="attach-icon" name="attach" />
                  )}
                  <Icon name="chevron" />
                </div>
              </div>
            ))
          )}
        </div>
      ) : (
        <div className={`log ${className}`}>
          <div className="log-header">
            <div className="arrow-container" onClick={handleSetView}>
              <Icon className="arrow-icon" name="arrow" flip />
              <Text
                className="log-title"
                text={t("pages.log.title")}
                size="xxxs"
              />
            </div>
            <div className="log-header-close-btn" onClick={onClose}>
              <Icon name="cross" />
            </div>
          </div>
          {selectedLogEvent && (
            <div className="log-column">
              <Text
                className={`${isMatchingLogUuid(selectedLogEvent.uuid)}`}
                text={
                  trimDate(selectedLogEvent.date) +
                  " - " +
                  selectedLogEvent.status +
                  " - " +
                  selectedLogEvent.codedesc
                }
                size="xxxs"
                fontWeight={600}
              />
              <LogDetails details={selectedLogEvent.details} />
              <Text
                className="log-column-value"
                text={t("pages.log.comment")}
                size="xs"
                fontWeight={600}
              />
              <Text
                className="log-column-value"
                text={
                  selectedLogEvent.comment === ""
                    ? "-"
                    : (parse(selectedLogEvent.comment) as string)
                }
                size="xxxs"
              />
            </div>
          )}
          <Attachments showTitle link_uuid={selectedLogEvent?.uuid} />
        </div>
      )}
    </>
  );
};

export default Log;
