import { currentApi } from "../utils/customer-config";

const { api, webSocketUrl } = currentApi();

const API_URL = api;
const WEB_SOCKET_URL = webSocketUrl;

export interface ApiConfig {
  url: string;
  timeout: number;
  webSocketUrl?: string;
}

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL,
  timeout: 8000,
  webSocketUrl: WEB_SOCKET_URL,
};
