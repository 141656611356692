import React, { useCallback, useState } from "react";
import PageLayout from "../../components/pagelayout/PageLayout";
import { Text } from "../../components/text/Text";
import { Icon } from "../../components/icon/Icon";
import "./Settings.scss";
import { Dropdown } from "../../components/dropdown/Dropdown";
import packageJson from "../../../package.json";
import {
  selectedLanguage,
  languages,
  i18nextLngKey,
} from "../../constants/Language";
import { map_layers } from "../../constants/MapLayers";
import { t } from "i18next";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import { useMobileView } from "../../context/mobile-view-context/MobileViewContext";

export const Settings = () => {
  const { isMobileView } = useMobileView();
  const [currentMapLayer, setCurrentMapLayer] = useState(
    localStorage.getItem(LOCAL_STORAGE.SELECTED_MAP_URL) ?? map_layers[0].value
  );

  const handleLanguageChange = useCallback((value: string | undefined) => {
    const newLanguage = value ?? selectedLanguage;
    localStorage.setItem(i18nextLngKey, newLanguage);
    window.location.reload();
  }, []);

  const handleMaplayerChange = useCallback((value: string | undefined) => {
    const newMapLayer = value ?? map_layers[0].value;
    setCurrentMapLayer(newMapLayer);
    localStorage.setItem(LOCAL_STORAGE.SELECTED_MAP_URL, newMapLayer);
  }, []);

  return (
    <PageLayout>
      <div className="settings-header">
        <Text text={t("pages.settings.title")} />
        <Icon name="settings" />
      </div>
      <div className="settings-content">
        <Dropdown
          title={t("pages.settings.language")}
          options={languages}
          selectedValue={
            localStorage.getItem(i18nextLngKey) ?? selectedLanguage
          }
          onSelectChange={handleLanguageChange}
        />
        <Dropdown
          classname="fit-content"
          title={
            isMobileView
              ? t("pages.settings.noMap")
              : t("pages.settings.mapLayer")
          }
          options={map_layers}
          selectedValue={currentMapLayer}
          onSelectChange={handleMaplayerChange}
          disabled={isMobileView}
        />
      </div>
      <div className="settings-footer">
        <div className="developed-by">
          <img src={"av_skogsgr.svg"} alt="Asplan Viak" />
          <p>{t("pages.settings.developedBy")}</p>
        </div>
        <div className="version">
          <p>v{packageJson.version}</p>
        </div>
      </div>
    </PageLayout>
  );
};
