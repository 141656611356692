import { AdaptiveUi } from "@avinet/adaptive-ui-core";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./i18n/i18n";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import getTheme from "./theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = getTheme();

root.render(
  <React.StrictMode>
    <AdaptiveUi theme={theme}>
      <App />
      <ToastContainer />
    </AdaptiveUi>
  </React.StrictMode>
);

reportWebVitals();
