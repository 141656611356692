import React from "react";
import { Text } from "../text/Text";

import { t } from "i18next";

export const SessionModalContent = ({ onclose }: { onclose: () => void }) => {
  return (
    <div className="session-modal-content">
      <div className="session-modal-content__text">
        <Text text={t("pages.orderInfo.sessionModal.text")} size="xs" />
      </div>
      <div className="session-modal-content__buttons">
        <button className="btn" onClick={onclose}>
          {t("pages.orderInfo.sessionModal.buttonText")}
        </button>
      </div>
    </div>
  );
};
