import React, { useEffect, ReactNode, useContext } from "react";
import WebSocketService from "../../services/WebSocketService";
import { UserContext } from "../../context/user-context/UserContext";
import { useWebSocket } from "../../context/websocket-context/WebSocketContext";
import { useOrder } from "../../context/order-context/OrderContext";

const WebSocketManager = ({ children }: { children: ReactNode }) => {
  const user = useContext(UserContext);
  const { setData } = useWebSocket();
  const { currentOrder } = useOrder();

  useEffect(() => {
    if (user.token) {
      WebSocketService.getInstance(setData).connect(
        user.token,
        parseInt(currentOrder?.route ?? "") ?? undefined
      );
    }
  }, [user.token, setData, currentOrder?.route]);

  return <>{children}</>;
};

export default WebSocketManager;
