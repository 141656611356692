import React, { useRef } from "react";
import "./Modal.scss";
import { Text } from "../text/Text";
import { Icon } from "../icon/Icon";

interface ModalProps {
  show: boolean;
  title?: string;
  icon?: React.ReactNode;
  provideOwnContent?: boolean;
  children: React.ReactNode;
  closeModal: () => void;
  noBackdropClose?: boolean;
}

const Modal = ({
  show,
  title,
  icon,
  provideOwnContent,
  children,
  closeModal,
  noBackdropClose,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: React.MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      closeModal();
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className="modal-backdrop"
      onClick={noBackdropClose ? undefined : handleClickOutside}
    >
      <div
        className={`modal-content ${
          provideOwnContent ? "fixed-margin-top" : ""
        }`}
        ref={modalRef}
      >
        {!provideOwnContent && (
          <>
            <div className="modal-top-row">
              {title && (
                <div className="modal-top-row-header">
                  {icon}
                  <Text
                    className="modal-header-top-row-title"
                    text={title}
                    size="xs"
                    fontWeight={600}
                  />
                </div>
              )}
              <div className="modal-close-button" onClick={closeModal}>
                <Icon name="cross" />
              </div>
            </div>
            <hr />
            <div className="modal-body">{children}</div>
          </>
        )}
        {provideOwnContent && children}
      </div>
    </div>
  );
};

export default Modal;
