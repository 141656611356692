import { DEFAULT_API_CONFIG } from "../api/api-config";
import { toast } from "react-toastify";
import { InternalMessage } from "../utils/data-types";

class WebSocketService {
  private static instance: WebSocketService;
  private socket: WebSocket | null = null;
  private token: string | null = null;

  private constructor(private setData: (data: InternalMessage) => void) {}

  public static getInstance(
    setData: (data: InternalMessage) => void
  ): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService(setData);
    }
    return WebSocketService.instance;
  }

  public connect(token: string, route: number | undefined): void {
    if (this.socket || !token) return;

    this.token = token;
    this.socket = new WebSocket(
      `wss://${DEFAULT_API_CONFIG.webSocketUrl}/api/diaryorder/tasks/connect?gm_session_id=${token}`
    );

    this.socket.onopen = () => {
      console.log("Connected to WebSocket");
    };

    this.socket.onmessage = (event) => {
      const data = event.data;

      if (data.startsWith("{")) {
        const parsedData = JSON.parse(data);
        if (parsedData.route === route || !route) {
          toast.info(`Ny intern melding: ${parsedData.info1}`, {
            toastId: "new-internal-message",
            position: "bottom-right",
            autoClose: false,
            draggable: true,
          });
        }
        // Update the context state with the new data
        this.setData(parsedData);
      }
    };

    this.socket.onclose = () => {
      console.log("WebSocket connection closed");
      this.socket = null; // Allow reconnecting
    };

    this.socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  }

  public disconnect(): void {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }
}

export default WebSocketService;
