import { Route, Routes } from "react-router-dom";

import {
  baseUrl,
  createNewPasswordUrl,
  enterCodeUrl,
  internalMessagesUrl,
  mapUrl,
  ordersOverviewUrl,
  resetPasswordUrl,
  settingsUrl,
} from "../constants/Routes";
import { ProtectedRoute } from "./ProtectedRoute";
import { UnprotectedRoute } from "./UnprotectedRoute";
import { Orders } from "../pages/orders/Orders";
import { Login } from "../pages/login/Login";
import { EnterCode } from "../pages/enter-code/EnterCode";
import { ResetPassword } from "../pages/reset-password/ResetPassword";
import { CreateNewPassword } from "../pages/create-new-password/CreateNewPassword";
import { Settings } from "../pages/settings/Settings";
import { Map } from "../pages/map/Map";
import OrderInfo from "../pages/order-info/OrderInfo";
import { DrivingProvider } from "../context/driving-context/DrivingContext";
import { OnRegisterProvider } from "../context/on-register-context/OnRegisterContext";
import { DrawingProvider } from "../context/drawing-context/DrawingContext";
import InternalMessages from "../pages/internal-messages/InternalMessages";
import { useContext, useState } from "react";
import Modal from "../components/modal/Modal";
import { SessionModalContent } from "../components/modal/SessionModalContent";
import { t } from "i18next";
import { UserContext } from "../context/user-context/UserContext";
import { InternalMessagesProvider } from "../context/internal-messages-context/InternalMessagesContext";

export function Router() {
  const { logoutUser } = useContext(UserContext);
  const [sessionExpiredModalOpen, setSessionExpiredModalOpen] = useState(false);

  const handleSessionExpired = () => {
    setSessionExpiredModalOpen(true);
  };

  const handleLogout = () => {
    setSessionExpiredModalOpen(false);
    logoutUser();
  };

  return (
    <InternalMessagesProvider>
      <DrivingProvider>
        <DrawingProvider>
          <OnRegisterProvider>
            <Modal
              show={sessionExpiredModalOpen}
              title={t("pages.orderInfo.sessionModal.title")}
              closeModal={handleLogout}
            >
              <SessionModalContent onclose={handleLogout} />
            </Modal>
            <Routes>
              <Route
                path={baseUrl}
                element={
                  <UnprotectedRoute>
                    <Login />
                  </UnprotectedRoute>
                }
              />
              <Route
                path={resetPasswordUrl}
                element={
                  <UnprotectedRoute>
                    <ResetPassword />
                  </UnprotectedRoute>
                }
              />
              <Route
                path={enterCodeUrl}
                element={
                  <UnprotectedRoute>
                    <EnterCode />
                  </UnprotectedRoute>
                }
              />
              <Route
                path={createNewPasswordUrl}
                element={
                  <UnprotectedRoute>
                    <CreateNewPassword />
                  </UnprotectedRoute>
                }
              />
              <Route
                path={ordersOverviewUrl}
                element={
                  <ProtectedRoute onSessionExpired={handleSessionExpired}>
                    <Orders />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`${ordersOverviewUrl}/:orderId/:selectedFilter`}
                element={
                  <ProtectedRoute onSessionExpired={handleSessionExpired}>
                    <OrderInfo />
                  </ProtectedRoute>
                }
              />
              <Route
                path={mapUrl}
                element={
                  <ProtectedRoute onSessionExpired={handleSessionExpired}>
                    <Map />
                  </ProtectedRoute>
                }
              />
              <Route
                path={internalMessagesUrl}
                element={
                  <ProtectedRoute onSessionExpired={handleSessionExpired}>
                    <InternalMessages />
                  </ProtectedRoute>
                }
              />
              <Route
                path={settingsUrl}
                element={
                  <ProtectedRoute onSessionExpired={handleSessionExpired}>
                    <Settings />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </OnRegisterProvider>
        </DrawingProvider>
      </DrivingProvider>
    </InternalMessagesProvider>
  );
}
