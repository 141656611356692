import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Text } from "../text/Text";
import "./RegisterObject.scss";
import { t } from "i18next";
import { TextButton } from "../buttons/TextButton";
import {
  IOrder,
  OrderObject,
  IRegisterData,
  IRegistrationDetails,
  ITagItem,
  DiaryDetails,
  InternalMessage,
  IDetail,
} from "../../utils/data-types";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import superagent from "superagent";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import { getResponse, setResponse } from "../../utils/indexedDb";
import { Icon } from "../icon/Icon";
import { STATUSES } from "../../constants/Constants";
import { Dropdown } from "../dropdown/Dropdown";
import { useDriving } from "../../context/driving-context/DrivingContext";
import { useOnRegister } from "../../context/on-register-context/OnRegisterContext";
import { internalMessagesUrl } from "../../constants/Routes";
import { UserContext } from "../../context/user-context/UserContext";
import { useInternalMessages } from "../../context/internal-messages-context/InternalMessagesContext";

const RegisterObject = ({
  className,
  dataFromOrderInfo,
  dataFromInternalMessages,
  selectedBoxId,
  onClose,
  selectedFilter,
}: {
  className?: string;
  dataFromOrderInfo?: IOrder | undefined;
  dataFromInternalMessages?: InternalMessage[] | undefined;
  selectedBoxId: number | null;
  onClose: () => void;
  selectedFilter: string | undefined;
}) => {
  const taskStatuses = [STATUSES.COMPLETED, STATUSES.NOT_COMPLETED];
  const [selectedStatus, setSelectedStatus] = useState(STATUSES.COMPLETED);
  const [selectedTask, setSelectedTask] = useState<
    OrderObject | InternalMessage
  >();
  const [selectedTaskDetails, setSelectedTaskDetails] = useState<
    IDetail[] | undefined
  >();
  const [selectedTagValues, setSelectedTagValues] = useState<string[]>([]);
  const [dataState, setDataState] = useState<IOrder | undefined>(
    dataFromOrderInfo
  );
  const [remark, setRemark] = useState<string | undefined>("");
  const [logResponse, setLogResponse] = useState<DiaryDetails[]>();
  const [showCountdown, setShowCountdown] = useState<boolean>(false);
  const { hasRegistered, onRegister } = useOnRegister();
  const { isDriving } = useDriving();
  const { user } = useContext(UserContext);
  const { updateInternalMessage, internalMessages } = useInternalMessages();

  const handleSetNewStatus = useCallback((status: string) => {
    setSelectedStatus(status as STATUSES);
  }, []);

  useEffect(() => {
    if (!selectedBoxId) return;
    let task;
    if (dataFromOrderInfo?.orderobject) {
      task = dataFromOrderInfo.orderobject.find(
        (task: OrderObject) => task.id === selectedBoxId
      );
      setSelectedTaskDetails(task?.orderdetails);
    } else if (dataFromInternalMessages) {
      task = dataFromInternalMessages.find(
        (task: InternalMessage) => task.id === selectedBoxId
      );
      setSelectedTaskDetails(task?.details);
    }
    if (!task) return;
    setSelectedTask(task);
  }, [dataFromInternalMessages, dataFromOrderInfo, selectedBoxId]);

  const setStatusOfSelectedTask = useCallback(() => {
    if (selectedTask?.status === STATUSES.NOT_COMPLETED) {
      handleSetNewStatus(STATUSES.NOT_COMPLETED);
    }
  }, [handleSetNewStatus, selectedTask?.status]);

  useEffect(() => {
    onRegister(false);
  }, [onRegister, selectedBoxId]);

  const selectedOrderDetailsTags = useMemo(() => {
    if (selectedTask) {
      if ("orderdetails" in selectedTask) {
        return selectedTask.orderdetails.map((orderDetail) => orderDetail.tag);
      } else if ("details" in selectedTask) {
        return selectedTask.details.map((detail) => detail.tag);
      }
    }
    return [];
  }, [selectedTask]);

  const tagsJsonified = useMemo(() => {
    return selectedOrderDetailsTags?.map((tag) => JSON.parse(tag));
  }, [selectedOrderDetailsTags]);

  useEffect(() => {
    setSelectedTagValues(
      selectedTaskDetails?.map((detail) => detail.val ?? "0") ?? []
    );
  }, [
    dataFromInternalMessages,
    dataFromOrderInfo,
    selectedTask,
    selectedTaskDetails,
  ]);

  useEffect(() => {
    setStatusOfSelectedTask();
  }, [selectedBoxId, setStatusOfSelectedTask]);

  useEffect(() => {
    onRegister(false);
  }, [selectedTagValues, selectedStatus, onRegister]);

  const tagTypes = useMemo(
    () =>
      tagsJsonified?.flatMap((arr) =>
        arr
          .filter(
            (item: ITagItem) =>
              item.key === "valuetype" && item.value && item.value.type
          )
          .map((item: ITagItem) => item.value.type)
      ),
    [tagsJsonified]
  );

  const tagListValues = useMemo(
    () =>
      tagsJsonified?.flatMap((arr) =>
        arr
          .filter(
            (item: ITagItem) =>
              item.key === "valuetype" &&
              item.value &&
              Object.prototype.hasOwnProperty.call(item.value, "values")
          )
          .map((item: ITagItem) => item.value.values)
      ),
    [tagsJsonified]
  );

  const lang = useMemo(() => {
    return localStorage.getItem("i18nextLng");
  }, []);

  const placeholderText = useMemo<string>(() => {
    const placeholderItem = tagsJsonified
      ?.flatMap((arr) =>
        arr.filter(
          (item: ITagItem) =>
            item.key === "valuetype" &&
            item.value &&
            Object.prototype.hasOwnProperty.call(
              item.value,
              `placeholder_${lang}`
            )
        )
      )
      .find((item: ITagItem) => item);

    if (placeholderItem?.value) {
      const placeholderKey = `placeholder_${lang}`;
      return placeholderItem.value[placeholderKey] || "";
    }

    return "";
  }, [tagsJsonified, lang]);

  const onTagValueChange = useCallback(
    (value: string | undefined, index: number) => {
      setSelectedTagValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] = value ?? "0";
        return newValues;
      });
    },
    []
  );

  const renderTagInput = useCallback(
    (inputType: string, index: number, inputValues?: string) => {
      const inputValuesArray =
        inputType === "list" ? inputValues?.split(",") : [];
      const finished =
        selectedTask?.status === STATUSES.COMPLETED ||
        selectedTask?.status === STATUSES.NOT_COMPLETED;
      switch (inputType) {
        case "textarea":
          return (
            <textarea
              id="remark"
              placeholder={t("pages.registerObject.textPlaceholder")}
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) =>
                (e.target.placeholder = t(
                  "pages.registerObject.textPlaceholder"
                ))
              }
            />
          );
        case "text":
          return (
            <Dropdown
              selectedValue={selectedTagValues[index]}
              placeholder={
                placeholderText !== ""
                  ? placeholderText
                  : t("pages.message.enterText")
              }
              onSelectChange={(value) => onTagValueChange(value, index)}
            />
          );
        case "number":
          return (
            <Dropdown
              selectedValue={
                selectedTagValues[index] === "" && finished
                  ? ""
                  : selectedTagValues[index]
              }
              placeholder={
                selectedTagValues[index] === "" && finished
                  ? "0"
                  : placeholderText !== ""
                  ? placeholderText
                  : t("pages.message.enterValue")
              }
              onSelectChange={(value) => onTagValueChange(value, index)}
              type="number"
            />
          );
        case "date":
          return (
            <Dropdown
              selectedValue={selectedTagValues[index]}
              placeholder={
                placeholderText !== ""
                  ? placeholderText
                  : t("pages.message.chooseDate")
              }
              onSelectChange={(value) => onTagValueChange(value, index)}
              type="date"
            />
          );
        case "list":
          return (
            <Dropdown
              selectedValue={selectedTagValues[index]}
              placeholder={
                placeholderText !== ""
                  ? placeholderText
                  : t("pages.message.chooseFromList")
              }
              onSelectChange={(value) => onTagValueChange(value, index)}
              options={inputValuesArray?.map((value) => ({
                value,
                label: value,
              }))}
            />
          );
        default:
          return null;
      }
    },
    [
      selectedTask?.status,
      remark,
      selectedTagValues,
      placeholderText,
      onTagValueChange,
    ]
  );

  const registerUrl = useMemo(
    () =>
      DEFAULT_API_CONFIG.url +
      "/diaryorder/diarydetailV2/updateMultipleDetails",
    []
  );

  const handleSetDataState = useCallback(() => {
    if (!selectedTask) return;
    if (dataFromOrderInfo) {
      const newData = { ...dataFromOrderInfo };
      newData.orderobject = newData.orderobject.map((task) => {
        if (task.id === selectedBoxId) {
          task.status = selectedStatus;
          task.orderdetails = task.orderdetails.map((detail, index) => {
            detail.val = selectedTagValues[index];
            return detail;
          });
        }
        return task;
      });
      setDataState(newData);
    } else if ("details" in selectedTask) {
      const newData = [...(internalMessages as Array<InternalMessage>)];
      const index = newData.findIndex(
        (task: InternalMessage) => task.id === selectedBoxId
      );
      if (index !== -1) {
        newData[index].status = selectedStatus;
        newData[index].details = newData[index].details.map((detail, index) => {
          detail.val = selectedTagValues[index];
          return detail;
        });
        updateInternalMessage(newData[index]);
      }
    }
  }, [
    selectedTask,
    dataFromOrderInfo,
    selectedBoxId,
    selectedStatus,
    selectedTagValues,
    internalMessages,
    updateInternalMessage,
  ]);

  const updateIndexedDB = useCallback(async () => {
    if (!selectedFilter) return;
    const orders = await getResponse(selectedFilter);
    const index = orders.findIndex(
      (order: IOrder) => order.id === dataState?.id
    );
    orders[index] = dataState;

    setResponse(selectedFilter, orders);
  }, [dataState, selectedFilter]);

  const saveFailedRegRequest = useCallback(
    async (requestBody: IRegisterData, userUuid: string) => {
      const allFailedRegRequests = [];
      const exisitingFailedRegRequests = await getResponse("failedRegRequests");

      if (exisitingFailedRegRequests)
        allFailedRegRequests.push(...exisitingFailedRegRequests);

      allFailedRegRequests.push({
        requestBody,
        userUuid,
      });

      setResponse("failedRegRequests", allFailedRegRequests);
    },
    []
  );

  const jobUuid = useMemo<string | null>(() => {
    return localStorage.getItem(LOCAL_STORAGE.JOB_UUID);
  }, []);

  const needStartweight = useMemo<boolean>(() => {
    return localStorage.getItem(LOCAL_STORAGE.NEED_STARTWEIGHT) === "true";
  }, []);

  const currentLogResponse = logResponse?.find(
    (logPost) => logPost.uuid === selectedTask?.fk_diary_uuid
  );

  const onRegisterObject = useCallback(() => {
    if (!selectedTask) return;
    const details: IRegistrationDetails[] = [];
    for (let i = 0; i < selectedTagValues.length; i++) {
      // orderObject
      if ("orderdetails" in selectedTask) {
        details.push({
          id: selectedTask.orderdetails[i].id,
          code: selectedTask.orderdetails[i].code,
          val: selectedTagValues[i] === "" ? "0" : selectedTagValues[i],
          job_uuid: jobUuid ?? "",
        });
        // internalMessages
      } else if ("details" in selectedTask) {
        details.push({
          id: selectedTask.details[i].id,
          code: selectedTask.details[i].code,
          val: selectedTagValues[i] === "" ? "0" : selectedTagValues[i],
          job_uuid: jobUuid ?? "",
        });
      }
    }

    const registerData: IRegisterData = {
      fk_diary_uuid: selectedTask.fk_diary_uuid,
      finished: selectedStatus === STATUSES.COMPLETED ? 1 : 2,
      executed_date: new Date().toISOString(),
      comment: (() => {
        const remark =
          (document.getElementById("remark") as HTMLTextAreaElement)?.value ||
          "";
        return "details" in selectedTask
          ? `${currentLogResponse?.comment}\n-----------\n${remark}`
          : remark;
      })(),
      details,
    };

    if (needStartweight) {
      const id = selectedTask.tbl_id;
      let registeredRestPPP;
      let registeredBio;

      for (let i = 0; i < details.length; i++) {
        if (details[i].code === "T205" || details[i].code === "T357") {
          registeredRestPPP = details[i].val;
        }
        if (details[i].code === "T365") {
          registeredBio = details[i].val;
        }
      }

      const totalWeightArray = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE.TOTAL_WEIGHT) || "[]"
      );

      type TotalWeightItem = {
        id: number;
        restPPP: string;
        bio?: string;
      };

      const existingEntryIndex = totalWeightArray.findIndex(
        (item: TotalWeightItem) => item.id === id
      );

      if (existingEntryIndex !== -1) {
        totalWeightArray[existingEntryIndex].restPPP = registeredRestPPP;
        totalWeightArray[existingEntryIndex].bio = registeredBio;
      } else {
        totalWeightArray.push({
          id: id,
          restPPP: registeredRestPPP,
          bio: registeredBio,
        });
      }

      localStorage.setItem(
        LOCAL_STORAGE.TOTAL_WEIGHT,
        JSON.stringify(totalWeightArray)
      );
    }

    async function postRequest() {
      if (!user.value?.session_id || !user.value?.uuid) return;

      const requestBody: IRegisterData = registerData;
      try {
        await superagent
          .post(registerUrl)
          .send(requestBody)
          .set("Accept", "application/json")
          .set("gm_session_id", user.value?.session_id);
      } catch (error) {
        console.error("Error Response:", error);
        await saveFailedRegRequest(requestBody, user.value?.uuid);
      }
    }
    onRegister(true);
    handleSetDataState();
    postRequest();
    updateIndexedDB();
    setShowCountdown(true);

    setTimeout(() => {
      setShowCountdown(false);
      onClose();
    }, 1000);
  }, [
    selectedTask,
    selectedStatus,
    needStartweight,
    onRegister,
    handleSetDataState,
    updateIndexedDB,
    selectedTagValues,
    jobUuid,
    currentLogResponse?.comment,
    user.value?.session_id,
    user.value?.uuid,
    registerUrl,
    saveFailedRegRequest,
    onClose,
  ]);

  const disableRegisterButton = useMemo<boolean>(() => {
    return (
      needStartweight &&
      !isDriving &&
      !window.location.pathname.includes(internalMessagesUrl)
    );
  }, [isDriving, needStartweight]);

  const logUrl = useMemo<string>(
    () => DEFAULT_API_CONFIG.url + "/diaryorder/diarydetailV2/getDiaryDetails",
    []
  );

  useEffect(() => {
    if (selectedBoxId) {
      const diaryDetailsRequest = {
        tbl_id: selectedTask?.tbl_id,
        digi_theme_uuid: selectedTask?.digi_theme_uuid,
      };

      const isPayloadValid =
        diaryDetailsRequest.tbl_id && diaryDetailsRequest.digi_theme_uuid;
      if (!isPayloadValid) {
        return;
      }

      const fetchLogPosts = async () => {
        if (!user.value?.session_id) return;
        try {
          const data = await superagent
            .post(logUrl)
            .send(diaryDetailsRequest)
            .set("Accept", "application/json")
            .set("gm_session_id", user.value?.session_id);

          setLogResponse(data.body);
        } catch (error) {
          console.error("Error fetching log posts:", error);
        }
      };

      fetchLogPosts();
    }
  }, [user.value?.session_id, logUrl, selectedBoxId, selectedTask]);

  useEffect(() => {
    if (logResponse && logResponse.length > 0) {
      const logPosts = logResponse;
      const orderObjectUuid = selectedTask?.fk_diary_uuid;
      const logPost = logPosts.find(
        (logPost: DiaryDetails) => logPost.uuid === orderObjectUuid
      );
      selectedTask && "orderdetails" in selectedTask
        ? setRemark(logPost?.comment)
        : setRemark("");
    }
  }, [logResponse, selectedTask]);

  return (
    <div className={`register-object ${className}`}>
      <div className="register-object-header">
        <Text
          className="register-object-header-title"
          text={t("pages.registerObject.title")}
          size="xxs"
          fontWeight={600}
        />
        <div
          className="register-object-header-close-btn"
          onClick={() => onClose()}
        >
          <Icon name="cross" />
        </div>
      </div>
      {!disableRegisterButton ? (
        <div className="register-object-form">
          {selectedTask && "comment" in selectedTask && (
            <pre>{currentLogResponse?.comment}</pre>
          )}
          {selectedTaskDetails &&
            selectedTaskDetails.map((detail, index) => (
              <div key={index} className="register-object-form-column">
                <Text text={detail.label} size="xxxs" fontWeight={600} />

                {renderTagInput(
                  tagTypes?.[index],
                  index,
                  tagListValues?.[index]
                )}
              </div>
            ))}
          <div className="register-object-form-column">
            <Text
              text={t("pages.registerObject.remark")}
              size="xxxs"
              fontWeight={600}
            />
            {renderTagInput("textarea", 0)}
          </div>
          <div className="register-object-status">
            <Text
              text={t("pages.registerObject.newStatus")}
              size="xxxs"
              fontWeight={600}
            />
            <div className="register-object-status-btns">
              {taskStatuses.map((status, index) => (
                <div
                  key={index}
                  className={`register-object-status-btn ${status} ${
                    selectedStatus === status ? "selected" : ""
                  }`}
                  onClick={() => handleSetNewStatus(status)}
                >
                  <div
                    className={`radioCheck ${status} ${
                      selectedStatus === status ? "selected" : ""
                    }`}
                  >
                    <div
                      className={`radioCheckDot ${status} ${
                        selectedStatus === status ? "selected" : ""
                      }`}
                    />
                  </div>
                  <Text text={t(`common.${status}`)} size="xxxs" />
                </div>
              ))}
            </div>
          </div>

          <div
            className={`register-object-form-btn-container ${
              hasRegistered ? "saved" : ""
            }`}
          >
            {showCountdown && (
              <div className="countdown-container">
                <div className="countdown-bar"></div>
              </div>
            )}
            <TextButton
              text={hasRegistered ? t("common.saved") : t("common.save")}
              onClick={onRegisterObject}
              invertColors={hasRegistered}
              disabled={hasRegistered}
              icon={hasRegistered ? <Icon name="checkmarkFluent" /> : undefined}
              iconPosition="right"
            />
          </div>
        </div>
      ) : (
        <div className="register-object-warning">
          <Icon name="warning" />
          <Text
            text={t("pages.registerObject.startWeightMissing")}
            size="xxs"
            fontWeight={400}
          />
        </div>
      )}
    </div>
  );
};

export default RegisterObject;
