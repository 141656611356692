import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { InternalMessage } from "../../utils/data-types";
import { useWebSocket } from "../websocket-context/WebSocketContext";

interface InternalMessagesContextType {
  internalMessages: InternalMessage[] | undefined;
  saveInternalMessages: (
    internalMessage: InternalMessage[] | undefined
  ) => void;
  addToInternalMessages: (internalMessage: InternalMessage) => void;
  updateInternalMessage: (internalMessage: InternalMessage) => void;
}

const InternalMessagesContext = createContext<
  InternalMessagesContextType | undefined
>(undefined);

interface InternalMessagesProviderProps {
  children: ReactNode;
}

export const InternalMessagesProvider = ({
  children,
}: InternalMessagesProviderProps) => {
  const [internalMessages, setInternalMessages] = useState<
    InternalMessage[] | undefined
  >(undefined);

  const saveInternalMessages = (
    internalMessages: InternalMessage[] | undefined
  ) => setInternalMessages(internalMessages);

  const websocket = useWebSocket();

  useEffect(() => {
    if (websocket.data) {
      setInternalMessages((prevInternalMessages) => {
        if (prevInternalMessages) {
          return [...prevInternalMessages, websocket.data as InternalMessage];
        } else {
          return [websocket.data as InternalMessage];
        }
      });
    }
  }, [websocket.data]);

  const addToInternalMessages = (internalMessage: InternalMessage) => {
    setInternalMessages((prevInternalMessages) => {
      if (prevInternalMessages) {
        return [...prevInternalMessages, internalMessage];
      } else {
        return [internalMessage];
      }
    });
  };

  const updateInternalMessage = (internalMessage: InternalMessage) => {
    setInternalMessages((prevInternalMessages) => {
      if (prevInternalMessages) {
        const updatedInternalMessages = prevInternalMessages.map((msg) => {
          if (msg.id === internalMessage.id) {
            return internalMessage;
          } else {
            return msg;
          }
        });

        return updatedInternalMessages;
      } else {
        return [internalMessage];
      }
    });
  };

  return (
    <InternalMessagesContext.Provider
      value={{
        internalMessages,
        saveInternalMessages,
        addToInternalMessages,
        updateInternalMessage,
      }}
    >
      {children}
    </InternalMessagesContext.Provider>
  );
};

export const useInternalMessages = () => {
  const context = useContext(InternalMessagesContext);

  if (context === undefined) {
    throw new Error(
      "useInternalMessages must be used within an InternalMessagesProvider"
    );
  }

  return context;
};
