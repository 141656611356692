import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Text } from "../text/Text";
import "./Message.scss";
import { t } from "i18next";
import { TextButton } from "../buttons/TextButton";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import WKT from "ol/format/WKT";
import {
  IMessage,
  IMessageObject,
  IFilter,
  IOrder,
  IVectorLayerFeature,
  OrderObject,
  ITagItem,
} from "../../utils/data-types";
import { Dropdown } from "../dropdown/Dropdown";
import superagent from "superagent";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import useFetchMessageFilters from "../../hooks/useFetchMessageFilters";
import { useDrawing } from "../../context/drawing-context/DrawingContext";
import { useOnRegister } from "../../context/on-register-context/OnRegisterContext";
import { projection } from "../../constants/Projection";
import { DRAWING_OBJECT_TYPES } from "../../constants/Constants";

const Message = ({
  className,
  dataFromMap,
  dataFromOrderinfo,
  selectedBoxId,
  onClose,
  digiThemeUuid,
  fetchVectorLayerFeatures,
  toggleHideMapOptions,
  sendSelectedCodeToParent,
}: {
  className?: string;
  dataFromMap?: IVectorLayerFeature | undefined;
  dataFromOrderinfo?: IOrder | undefined;
  selectedBoxId: number | null;
  onClose: () => void;
  digiThemeUuid?: string;
  fetchVectorLayerFeatures?: () => void;
  toggleHideMapOptions?: () => void;
  sendSelectedCodeToParent?: (code: string) => void;
}) => {
  const [data, setData] = useState<IVectorLayerFeature | OrderObject>();
  const { hasRegistered, onRegister } = useOnRegister();

  const {
    isDrawMode,
    setIsDrawMode,
    setDrawnObject,
    drawnObject,
    isMovingPoint,
    setIsMovingPoint,
    isSendingWKT,
    setDrawnObjectType,
  } = useDrawing();
  const [showDetailsDropdowns, setShowDetailsDropdowns] = useState(false);
  const [showAddDetailButton, setShowAddDetailButton] = useState(false);
  const [showPushDetailButton, setShowPushDetailButton] = useState(false);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [imageBase64, setImageBase64] = useState<string | undefined>();
  const [imageExtension, setImageExtension] = useState<string | undefined>();
  const [fileName, setFileName] = useState<string | undefined>();
  const [isHidden, setIsHidden] = useState(false);
  const [showCountdown, setShowCountdown] = useState<boolean>(false);

  useEffect(() => {
    if (dataFromOrderinfo && selectedBoxId) {
      const task = dataFromOrderinfo.orderobject.find(
        (task: OrderObject) => task.id === selectedBoxId
      );
      if (!task) return;
      setData(task);
    } else if (dataFromMap) {
      setData(dataFromMap);
    }
  }, [dataFromMap, dataFromOrderinfo, selectedBoxId]);

  const messageCodesUrl = useMemo(
    () => DEFAULT_API_CONFIG.url + "/diaryorder/codeV2/get",
    []
  );

  // message types

  const [types, setTypes] = useState<IFilter[]>([]);

  const typesRequest = useMemo(
    () => ({
      digi_theme_uuid: data?.digi_theme_uuid ?? digiThemeUuid,
      field_name: isSendingWKT ? "message_type" : "diary_group_code",
    }),
    [data?.digi_theme_uuid, digiThemeUuid, isSendingWKT]
  );

  const fetchTypes = useFetchMessageFilters(messageCodesUrl, typesRequest);

  const handleFetchTypes = useCallback(async () => {
    try {
      const fetchedMessageFilters = await fetchTypes();
      setTypes(fetchedMessageFilters);
      return fetchedMessageFilters;
    } catch (error) {
      console.error("Error fetching types:", error);
      throw error;
    }
  }, [fetchTypes]);

  useEffect(() => {
    if (data?.digi_theme_uuid || isSendingWKT) {
      handleFetchTypes();
    }
  }, [typesRequest, data?.digi_theme_uuid, isSendingWKT, handleFetchTypes]);

  const [selectedType, setSelectedType] = useState("");

  // message codes

  const [codes, setCodes] = useState([]);

  const codesRequest = useMemo(
    () => ({
      digi_theme_uuid: data?.digi_theme_uuid ?? digiThemeUuid,
      field_name: isSendingWKT ? "message_code" : "diary_code",
      rel_code: selectedType,
    }),
    [data?.digi_theme_uuid, digiThemeUuid, isSendingWKT, selectedType]
  );

  const fetchCodes = useFetchMessageFilters(messageCodesUrl, codesRequest);

  const handleFetchCodes = useCallback(async () => {
    try {
      const fetchedMessageFilters = await fetchCodes();
      setCodes(fetchedMessageFilters);
      return fetchedMessageFilters;
    } catch (error) {
      console.error("Error fetching codes:", error);
      throw error;
    }
  }, [fetchCodes]);

  useEffect(() => {
    if (selectedType && (data?.digi_theme_uuid || digiThemeUuid)) {
      handleFetchCodes();
    }
  }, [
    codesRequest,
    selectedType,
    data?.digi_theme_uuid,
    digiThemeUuid,
    handleFetchCodes,
  ]);

  const [selectedCode, setSelectedCode] = useState("");

  const liftSelectedCode = useCallback(() => {
    if (sendSelectedCodeToParent) sendSelectedCodeToParent(selectedCode);
  }, [selectedCode, sendSelectedCodeToParent]);

  // detailGroups

  const [detailGroups, setDetailGroups] = useState<IFilter[]>();

  const detailGroupRequest = useMemo(
    () => ({
      digi_theme_uuid: data?.digi_theme_uuid,
      field_name: "detail_group_code",
      rel_code: selectedCode,
    }),
    [selectedCode, data?.digi_theme_uuid]
  );

  // if type or code changes, reset detailGroups
  useEffect(() => {
    setShowDetailsDropdowns(false);
    setShowPushDetailButton(false);
  }, [selectedType, selectedCode]);

  const fetchDetailGroups = useFetchMessageFilters(
    messageCodesUrl,
    detailGroupRequest
  );

  const handleFetchDetailGroups = useCallback(async () => {
    try {
      const fetchedMessageFilters = await fetchDetailGroups();
      setDetailGroups(fetchedMessageFilters);
      return fetchedMessageFilters;
    } catch (error) {
      console.error("Error fetching detail groups:", error);
      throw error;
    }
  }, [fetchDetailGroups]);

  useEffect(() => {
    if (selectedCode && data?.digi_theme_uuid) {
      handleFetchDetailGroups();
    }
  }, [
    detailGroupRequest,
    selectedCode,
    data?.digi_theme_uuid,
    handleFetchDetailGroups,
  ]);

  const [selectedDetailType, setSelectedDetailType] = useState("");

  // detailCodes

  const [detailCodes, setDetailCodes] = useState<IFilter[]>();

  const detailCodeRequest = useMemo(
    () => ({
      digi_theme_uuid: data?.digi_theme_uuid,
      field_name: "detail_code",
      rel_code: selectedDetailType,
    }),
    [selectedDetailType, data?.digi_theme_uuid]
  );

  const fetchDetailCodes = useFetchMessageFilters(
    messageCodesUrl,
    detailCodeRequest
  );

  const handleFetchDetailCodes = useCallback(async () => {
    try {
      const fetchedMessageFilters = await fetchDetailCodes();
      setDetailCodes(fetchedMessageFilters);
      return fetchedMessageFilters;
    } catch (error) {
      console.error("Error fetching detail codes:", error);
      throw error;
    }
  }, [fetchDetailCodes]);

  useEffect(() => {
    if (selectedDetailType && data?.digi_theme_uuid) {
      handleFetchDetailCodes();
    }
  }, [
    detailCodeRequest,
    selectedDetailType,
    data?.digi_theme_uuid,
    handleFetchDetailCodes,
  ]);

  const [selectedDetailCodedesc, setSelectedDetailCodedesc] = useState("");

  const [addedDetails, setAddedDetails] = useState<
    { detailType: string; detailCode: string; val?: string }[]
  >([]);

  const handleDropdownTypeChange = useCallback((value: string | undefined) => {
    setSelectedType(value ?? "");
  }, []);

  const handleDropdownCodeChange = useCallback((value: string | undefined) => {
    setSelectedCode(value ?? "");
  }, []);

  const handleDropdownDetailChange = useCallback(
    (value: string | undefined) => {
      setSelectedDetailType(value ?? "");
    },
    []
  );

  const handleDropdownDetailCodeChange = useCallback(
    (value: string | undefined) => {
      setSelectedDetailCodedesc(value ?? "");
    },
    []
  );

  const selectedCodeTag = useMemo(() => {
    const codeObject = codes?.find(
      (code: IFilter) => code.code === selectedCode
    ) as IFilter | undefined;
    if (codeObject && codeObject.tag) {
      return codeObject.tag;
    }
  }, [codes, selectedCode]);

  const selectedCodeJsonified = useMemo(() => {
    if (selectedCodeTag) {
      return JSON.parse(selectedCodeTag);
    }
  }, [selectedCodeTag]);

  const isDigitizePoint = useMemo(() => {
    if (selectedCodeJsonified) {
      return (
        selectedCodeJsonified.find((item: ITagItem) => item.key === "digitize")
          ?.value === "true"
      );
    }
  }, [selectedCodeJsonified]);

  const hideSelfAndMapOptions = useCallback(() => {
    setIsHidden(true);
    if (toggleHideMapOptions) toggleHideMapOptions();
  }, [toggleHideMapOptions]);

  useEffect(() => {
    if (drawnObject && !isDrawMode) {
      setIsHidden(false);
    }
  }, [drawnObject, isDrawMode]);

  const handleMovePoint = useCallback(() => {
    hideSelfAndMapOptions();
    liftSelectedCode();
    setIsDrawMode(true);
    setIsMovingPoint(true);
    setDrawnObjectType(DRAWING_OBJECT_TYPES.POINT);
  }, [
    hideSelfAndMapOptions,
    liftSelectedCode,
    setDrawnObjectType,
    setIsDrawMode,
    setIsMovingPoint,
  ]);

  useEffect(() => {
    setIsDrawMode(false);
    setIsMovingPoint(false);
  }, [selectedBoxId, setIsDrawMode, setIsMovingPoint]);

  const selectedDetailCodeObjectTag = useMemo(() => {
    const detailCodeObject = detailCodes?.find(
      (detailCode: IFilter) => detailCode.codedesc === selectedDetailCodedesc
    );
    if (detailCodeObject && detailCodeObject.tag) {
      return detailCodeObject.tag;
    }
  }, [detailCodes, selectedDetailCodedesc]);

  const tagJsonified = useMemo(() => {
    if (selectedDetailCodeObjectTag) {
      return JSON.parse(selectedDetailCodeObjectTag);
    }
  }, [selectedDetailCodeObjectTag]);

  const showTagInputField = useMemo(() => {
    if (
      selectedDetailCodedesc !== "" &&
      selectedDetailType !== "" &&
      tagJsonified
    ) {
      return true;
    }
    return false;
  }, [selectedDetailCodedesc, selectedDetailType, tagJsonified]);

  // if detail type and detail code are selected,
  // and there are existing detailGroups, show add detail button
  useEffect(() => {
    if (selectedCode !== "" && detailGroups && detailGroups.length > 0) {
      setShowAddDetailButton(true);
    } else {
      setShowAddDetailButton(false);
    }
  }, [selectedCode, detailGroups, addedDetails.length]);

  const tagInputType = useMemo<string>(() => {
    if (tagJsonified) {
      const valueTypeObj = tagJsonified.find(
        (item: ITagItem) => item.key === "valuetype"
      );
      return valueTypeObj?.value?.type;
    }
  }, [tagJsonified]);

  const tagInputListValues = useMemo<string>(() => {
    if (tagJsonified) {
      const valueTypeObj = tagJsonified.find(
        (item: ITagItem) => item.key === "valuetype"
      );
      return valueTypeObj?.value?.values;
    }
  }, [tagJsonified]);

  const [selectedTagValue, setSelectedTagValue] = useState<string | undefined>(
    ""
  );

  const onTagValueChange = useCallback((value: string | undefined) => {
    setSelectedTagValue(value);
  }, []);

  useEffect(() => {
    if (
      (selectedDetailCodedesc !== "" &&
        (tagJsonified === undefined || tagJsonified.length === 0)) ||
      (tagJsonified && selectedTagValue !== "")
    ) {
      setShowPushDetailButton(true);
    } else {
      setShowPushDetailButton(false);
    }
  }, [selectedDetailCodedesc, selectedTagValue, tagJsonified]);

  useEffect(() => {
    setShowPushDetailButton(false);
  }, [selectedType]);

  const renderTagInput = useCallback(
    (inputType: string, inputValues?: string) => {
      const inputValuesArray = inputValues?.split(",");
      switch (inputType) {
        case "text":
          return (
            <Dropdown
              title={t("pages.message.value")}
              selectedValue={selectedTagValue}
              placeholder={t("pages.message.enterText")}
              onSelectChange={onTagValueChange}
            />
          );
        case "number":
          return (
            <Dropdown
              title={t("pages.message.value")}
              selectedValue={selectedTagValue}
              placeholder={t("pages.message.enterValue")}
              onSelectChange={onTagValueChange}
              type="number"
            />
          );
        case "date":
          return (
            <Dropdown
              title={t("pages.message.date")}
              selectedValue={selectedTagValue}
              placeholder={t("pages.message.chooseDate")}
              onSelectChange={onTagValueChange}
              type="date"
            />
          );
        case "list":
          return (
            <Dropdown
              title={t("pages.message.value")}
              selectedValue={selectedTagValue}
              placeholder={t("pages.message.chooseFromList")}
              onSelectChange={onTagValueChange}
              options={inputValuesArray?.map((value) => ({
                value,
                label: value,
              }))}
            />
          );
        default:
          return null;
      }
    },
    [selectedTagValue, onTagValueChange]
  );

  const handleAddDetail = useCallback(() => {
    if (selectedDetailType && selectedDetailCodedesc) {
      setAddedDetails((prevDetails) => [
        ...prevDetails,
        {
          detailType: selectedDetailType,
          detailCode: selectedDetailCodedesc,
          val: selectedTagValue,
        },
      ]);
      setSelectedDetailType("");
      setSelectedDetailCodedesc("");
      setSelectedTagValue("");
      setShowDetailsDropdowns(false);
      setShowPushDetailButton(false);
    }
  }, [selectedDetailCodedesc, selectedDetailType, selectedTagValue]);

  const handleRemoveDetail = useCallback((index: number) => {
    setAddedDetails((prevDetails) => prevDetails.filter((_, i) => i !== index));
  }, []);

  const showDetailContainer = useMemo(
    () => addedDetails.length > 0,
    [addedDetails]
  );

  // if selectedType changes, reset form
  useEffect(() => {
    setSelectedCode("");
    setSelectedDetailType("");
    setAddedDetails([]);
  }, [selectedType]);

  // if selectedCode changes, reset form except selectedCode
  useEffect(() => {
    setSelectedDetailType("");
    setAddedDetails([]);
    if (isMovingPoint) setDrawnObject(null);
  }, [isMovingPoint, selectedCode, setDrawnObject]);

  // if selectedDetailType changes, reset form except selectedCode and selectedDetailType
  useEffect(() => {
    setSelectedDetailCodedesc("");
    setSelectedTagValue("");
  }, [selectedDetailType]);

  // if selectedDetailCodedesc changes, reset form except selectedCode, selectedDetailType, and selectedDetail
  useEffect(() => {
    setSelectedTagValue("");
  }, [selectedDetailCodedesc]);

  // if selectedBoxId changes, reset form
  useEffect(() => {
    setSelectedType("");
    setSelectedCode("");
    setSelectedDetailType("");
    setSelectedDetailCodedesc("");
    setSelectedTagValue("");
    (document.getElementById("description") as HTMLTextAreaElement).value = "";
    setAddedDetails([]);
    setFilePreview(null);
    setImageBase64(undefined);
    setImageExtension(undefined);
    setFileName(undefined);
  }, [selectedBoxId]);

  useEffect(() => {
    onRegister(false);
  }, [
    selectedType,
    selectedCode,
    selectedDetailType,
    selectedDetailCodedesc,
    drawnObject,
    onRegister,
  ]);

  const handleShowDetailsDropdowns = useCallback(() => {
    setShowDetailsDropdowns(true);
    setShowAddDetailButton(false);
  }, []);

  const shouldDisableSaveButton = useMemo(() => {
    if (
      selectedType === "" ||
      selectedCode === "" ||
      selectedDetailType !== ""
    ) {
      return true;
    }
    if (
      showDetailsDropdowns &&
      (selectedDetailType === "" || selectedDetailCodedesc === "")
    ) {
      return true;
    }
    return false;
  }, [
    selectedType,
    selectedCode,
    selectedDetailType,
    selectedDetailCodedesc,
    showDetailsDropdowns,
  ]);

  const drawnObjectAsWKT = useMemo(() => {
    if (!drawnObject || drawnObject?.getFlatCoordinates().length === 0)
      return "";
    const transformedDrawnObject = drawnObject.clone();
    transformedDrawnObject.transform("EPSG:3857", projection);
    const wkt = new WKT();
    return wkt.writeGeometry(transformedDrawnObject);
  }, [drawnObject]);

  const onSendMessage = useCallback(async () => {
    let requestBody: IMessage | IMessageObject;
    let messageApiUrl = "";
    if (isSendingWKT) {
      requestBody = {
        type: selectedType,
        code: selectedCode,
        description: (
          document.getElementById("description") as HTMLTextAreaElement
        )?.value,
        wkt: drawnObjectAsWKT,
      };
      messageApiUrl = drawnObjectAsWKT?.includes("LINESTRING")
        ? DEFAULT_API_CONFIG.url + "/diaryorder/messageV2/createline"
        : DEFAULT_API_CONFIG.url + "/diaryorder/messageV2/createpoint";
    } else {
      requestBody = {
        uuid: "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function (c) {
            const r = (Math.random() * 16) | 0,
              v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          }
        ),
        reported_date: new Date().toISOString(),
        code: selectedCode,
        layer_name: data?.layer_name,
        tbl_id: data?.tbl_id,
        digi_theme_uuid: data?.digi_theme_uuid,
        comment: (document.getElementById("description") as HTMLTextAreaElement)
          ?.value,
        wkt: data?.wkt,
        details: addedDetails.map((detail) => ({
          code: detail.detailCode.split(" ")[0],
          val: detail.val ?? "",
        })),
        image: imageBase64,
        imageExtension: imageExtension,
        fileName: fileName,
      };
      messageApiUrl =
        DEFAULT_API_CONFIG.url +
        "/diaryorder/diarydetailV2/createMultipleDetailsGeneric";
    }

    const user_store = localStorage.getItem(LOCAL_STORAGE.USER);
    const userObject = JSON.parse(user_store ?? "{}");
    const gm_session_id = userObject.session_id;

    try {
      // Send message request
      await superagent
        .post(messageApiUrl)
        .send(requestBody)
        .set("Accept", "application/json")
        .set("gm_session_id", gm_session_id);
    } catch (error) {
      console.error("Error sending message:", error);
    }
    if (isSendingWKT && fetchVectorLayerFeatures) fetchVectorLayerFeatures();
    onRegister(true);
    setShowCountdown(true);
    setTimeout(() => {
      setShowCountdown(false);
      onClose();
    }, 1000);
  }, [
    isSendingWKT,
    fetchVectorLayerFeatures,
    onRegister,
    selectedType,
    selectedCode,
    drawnObjectAsWKT,
    data?.layer_name,
    data?.tbl_id,
    data?.digi_theme_uuid,
    data?.wkt,
    addedDetails,
    imageBase64,
    imageExtension,
    fileName,
    onClose,
  ]);

  const addDetailText = useMemo(() => {
    if (addedDetails.length === 0) {
      return t("pages.message.addDetail");
    } else if (addedDetails.length > 0) {
      return t("pages.message.addMoreDetails");
    }
    return "";
  }, [addedDetails.length]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleAddPictureClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result as string;
        setFilePreview(result);
        setImageBase64(result.split(",")[1] || ""); // Base64 string without the prefix
        setImageExtension(file.type.split("/")[1]);
        setFileName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  const selectedTask = useMemo(() => {
    if (dataFromOrderinfo) {
      return dataFromOrderinfo.orderobject.find(
        (task: OrderObject) => task.id === selectedBoxId
      );
    } else if (dataFromMap) {
      return dataFromMap;
    }
  }, [dataFromMap, dataFromOrderinfo, selectedBoxId]);

  const movePoint = useCallback(async () => {
    if (!selectedTask) return;
    const requestBody: IMessage = {
      digi_theme_uuid: selectedTask.digi_theme_uuid,
      reported_date: new Date().toISOString(),
      code: selectedCode,
      layer_name: selectedTask.layer_name,
      tbl_id: selectedTask.tbl_id,
      comment: "",
      wkt: drawnObjectAsWKT,
      details: [],
    };

    const user_store = localStorage.getItem(LOCAL_STORAGE.USER);
    const userObject = JSON.parse(user_store ?? "{}");
    const gm_session_id = userObject.session_id;

    try {
      await superagent
        .post(
          DEFAULT_API_CONFIG.url +
            "/diaryorder/diarydetailV2/createMultipleDetailsGeneric"
        )
        .send(requestBody)
        .set("Accept", "application/json")
        .set("gm_session_id", gm_session_id);
    } catch (error) {
      console.error("Error moving point:", error);
    }
    onRegister(true);
  }, [drawnObjectAsWKT, onRegister, selectedCode, selectedTask]);

  return (
    <div
      className={`message ${className} 
    ${isHidden ? "hidden" : ""}
    `}
    >
      <div className="message-header">
        <Text
          className="message-header-title"
          text={t("pages.message.title")}
          size="xxs"
          fontWeight={600}
        />
        <div className="message-header-close-btn" onClick={onClose}>
          <Icon name="cross" />
        </div>
      </div>
      <div className="message-form">
        <Dropdown
          title={t("pages.message.type")}
          options={types?.map((type) => ({
            value: type.code,
            label: type.codedesc,
          }))}
          selectedValue={selectedType}
          placeholder={t("pages.message.chooseType")}
          onSelectChange={handleDropdownTypeChange}
        />
        {selectedType !== "" && (
          <Dropdown
            title={t("pages.message.code")}
            options={codes?.map((code: IFilter) => ({
              value: code.code,
              label: code.codedesc,
            }))}
            selectedValue={selectedCode}
            placeholder={t("pages.message.chooseCode")}
            onSelectChange={handleDropdownCodeChange}
          />
        )}
        {
          <div className="message-form-detail-container">
            {showDetailsDropdowns && (
              <>
                <div className="separator" />
                <Dropdown
                  title={t("pages.message.detail")}
                  options={detailGroups?.map((detailGroup: IFilter) => ({
                    value: detailGroup.code,
                    label: detailGroup.codedesc,
                  }))}
                  selectedValue={selectedDetailType}
                  placeholder={t("pages.message.chooseDetail")}
                  onSelectChange={handleDropdownDetailChange}
                />
              </>
            )}

            {selectedDetailType !== "" &&
              detailCodes &&
              detailCodes.length > 0 && (
                <Dropdown
                  title={t("pages.message.detailCode")}
                  options={detailCodes?.map((detailCode: IFilter) => ({
                    value: detailCode.codedesc,
                    label: detailCode.codedesc,
                  }))}
                  selectedValue={selectedDetailCodedesc}
                  placeholder={t("pages.message.chooseDetailCode")}
                  onSelectChange={handleDropdownDetailCodeChange}
                />
              )}
            {showTagInputField &&
              renderTagInput(tagInputType, tagInputListValues)}
            {showAddDetailButton && (
              <div className="detail-btn-container">
                <TextButton
                  className="add-detail-btn"
                  text={addDetailText}
                  onClick={handleShowDetailsDropdowns}
                />
              </div>
            )}
            {showPushDetailButton && (
              <div className="detail-btn-container">
                <button
                  className="btn push-detail-btn"
                  onClick={handleAddDetail}
                >
                  <Icon name="checkmarkFluent" />
                </button>
              </div>
            )}
          </div>
        }
        {showDetailContainer && (
          <div className="message-form-added-details-container">
            {addedDetails.map((detail, index) => (
              <div key={index} className="detail-item">
                <span>
                  {detail.detailCode} - {detail.val}
                </span>

                <div
                  className="remove-detail-btn"
                  onClick={() => handleRemoveDetail(index)}
                >
                  <Icon name="cross" />
                </div>
              </div>
            ))}
          </div>
        )}

        {isDigitizePoint && (
          <div className="message-form-draw-btn-container">
            <TextButton
              text={
                drawnObject
                  ? t("pages.message.editPoint")
                  : t("pages.message.choosePoint")
              }
              onClick={handleMovePoint}
              icon={
                drawnObject ? (
                  <Icon name="edit" />
                ) : (
                  <Icon name="mapDestination" />
                )
              }
              iconPosition="right"
            />
            {drawnObject && (
              <Text
                text={drawnObjectAsWKT}
                size="xxxs"
                fontWeight={600}
                className="drawn-object"
              />
            )}
          </div>
        )}

        <div className="message-form-column">
          <Text
            text={t("pages.message.description")}
            size="xxxs"
            fontWeight={600}
          />
          <textarea
            id="description"
            placeholder={t("pages.message.descriptionPlaceholder")}
            onFocus={(e) => (e.target.placeholder = "")}
            onBlur={(e) =>
              (e.target.placeholder = t("pages.message.descriptionPlaceholder"))
            }
          />
        </div>

        {filePreview && (
          <div className="file-preview">
            <img src={filePreview} alt="Preview" />
          </div>
        )}

        {showCountdown && (
          <div className="countdown-container">
            <div className="countdown-bar"></div>
          </div>
        )}

        <div className="message-form-btn-container">
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".png,.jpg,.jpeg"
            onChange={handleFileChange}
          />
          <TextButton
            text={t("pages.message.addAttachment")}
            onClick={handleAddPictureClick}
            invertColors
          />

          <div
            className={`message-form-btn-container ${
              hasRegistered ? "saved" : ""
            }`}
          >
            <TextButton
              text={hasRegistered ? t("common.saved") : t("common.save")}
              onClick={isDigitizePoint ? movePoint : onSendMessage}
              invertColors={hasRegistered}
              disabled={
                hasRegistered ||
                shouldDisableSaveButton ||
                (isDigitizePoint && !drawnObject) ||
                (tagJsonified !== undefined && tagJsonified.length > 0)
              }
              icon={hasRegistered ? <Icon name="checkmarkFluent" /> : undefined}
              iconPosition="right"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
